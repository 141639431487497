/* eslint-disable no-param-reassign */
import { customBugsnagLogger } from '@/utils/bugsnag';
import {
	ApolloLink,
	Operation,
	FetchResult,
	Observable,
	ApolloClient,
	InMemoryCache,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { split } from '@apollo/client/link/core';
import { HttpLink } from '@apollo/client/link/http';
import { print, getOperationAST } from 'graphql';
import Cookies from 'universal-cookie';

type SSELinkOptions = EventSourceInit & { uri: string };

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	let token = null;
	if (typeof window !== 'undefined') {
		token = localStorage.getItem('token');
	}

	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ?? '',
		},
	};
});

const handleExpired = () => {
	const cookies = new Cookies();
	cookies.remove('access_token', { path: '/' });
	cookies.remove('granted_access', { path: '/' });
	localStorage.removeItem('token');
	localStorage.removeItem('user');
	window.location.href = '/';
};

const errorLink = onError(({ graphQLErrors, networkError, response, operation, forward }) => {
	console.log("entro al error...", response, operation, forward);

	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path }) => {
			console.log(
				`[GraphQL error]------: Message: ${message}, Location: ${locations}, Path: ${path}`
			);

			// handle expired token
			if (message === 'An unknown token error occurred') {
				handleExpired();
			}
		});
	}
	if (networkError) {
		console.log(`[Network error]: ${networkError}`);
	}
	return forward(operation);

});
class SSELink extends ApolloLink {
	private eventSources: Map<string, EventSource> = new Map();

	constructor(private options: SSELinkOptions) {
		super();
	}

	request(operation: Operation): Observable<FetchResult> {
		const url = new URL(this.options.uri);
		url.searchParams.append('query', print(operation.query));

		const updateSubscriptionState = (operationName: any, status: any) => {
			const event = new CustomEvent('subscriptionStatusChanged', {
				detail: { operationName, status },
			});
			window.dispatchEvent(event);
		};

		if (operation.operationName) {
			url.searchParams.append('operationName', operation.operationName);
		}
		if (operation.variables) {
			url.searchParams.append('variables', JSON.stringify(operation.variables));
		}

		if (operation.extensions) {
			url.searchParams.append(
				'extensions',
				JSON.stringify(operation.extensions)
			);
		}

		return new Observable((sink) => {
			const eventSource = new EventSource(url.toString(), this.options);
			const operationName = operation.operationName || 'anonymous';

			this.eventSources.set(operationName, eventSource);

			eventSource.onopen = (event) => {
				updateSubscriptionState(operationName, true);
				customBugsnagLogger.debug(`subscribed to ${operationName}`);
				console.log(`Subscription for ${operationName} is open.`);
				customBugsnagLogger.debug(`Subscription for ${operationName} created.`);
			};

			eventSource.onmessage = (event) => {
				const data = JSON.parse(event.data);
				sink.next(data);
				if (eventSource.readyState === 2) {
					sink.complete();
				}
			};

			eventSource.onerror = (error) => {
				if (eventSource.readyState === EventSource.CONNECTING) {
					updateSubscriptionState(operationName, false);

					console.log(`Subscription error for ${operationName} CONNECTING`);
					customBugsnagLogger.debug(
						`Subscription error for ${operationName} CONNECTING`
					);
				} else if (eventSource.readyState === EventSource.CLOSED) {
					updateSubscriptionState(operationName, false);

					console.log(`Subscription for ${operationName} was closed.`);
					customBugsnagLogger.debug(
						`Subscription for ${operationName} was closed.`
					);
				} else {
					console.log(`Subscription error for ${operationName} Created`);
					customBugsnagLogger.debug(
						`Subscription error for ${operationName} OPEN`
					);
				}

				if (eventSource.readyState !== EventSource.CONNECTING) {
					updateSubscriptionState(operationName, false);

					sink.error(error);
					eventSource.close();
				}
			};

			return () => {
				updateSubscriptionState(operationName, false);
				console.log('returning error, closing event source and deleting');
				this.eventSources.get(operationName)?.close();
				this.eventSources.delete(operationName);
			};
		});
	}
}

const uri = process.env.GRAPHQL_ENDPOINT;
const sseLink = new SSELink({ uri });
const httpLink = new HttpLink({ uri });

const link = split(
	({ query, operationName }) => {
		const definition = getOperationAST(query, operationName);

		return (
			definition?.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	sseLink,
	httpLink
);

const cache = new InMemoryCache({
	typePolicies: {
		ClassUser: {
			fields: {
				Connections: {
					merge(existing = [], incoming = []) {
						if (!incoming) {
							return [];
						}
						return [...existing, ...incoming];
					},
				},
				getUsersInLobby: {
					merge(existing = [], incoming = []) {
						// custom merge logic here
						// return the merged data
						return [...existing, ...incoming];
					},
				},
				getStudentsInClass: {
					merge(existing = [], incoming = []) {
						// custom merge logic here
						// return the merged data
						return [...existing, ...incoming];
					},
				},
				getInstructorsInClass: {
					merge(existing = [], incoming = []) {
						// custom merge logic here
						// return the merged data
						return [...existing, ...incoming];
					},
				},
			},
		},
		Subscription: {
			fields: {
				getUsersInLobby: {
					merge(existing = [], incoming = []) {
						// custom merge logic here
						// return the merged data
						return [...existing, ...incoming];
					},
				},
				getStudentsInClass: {
					merge(existing = [], incoming = []) {
						// custom merge logic here
						// return the merged data
						return [...existing, ...incoming];
					},
				},
				getInstructorsInClass: {
					merge(existing = [], incoming = []) {
						// custom merge logic here
						// return the merged data
						return [...existing, ...incoming];
					},
				},
			},
		},
	},
});
export const client = new ApolloClient({
	link:  errorLink.concat(authLink.concat(link)),
	cache,
});
