import Bugsnag from '@bugsnag/js';

console.log('MAC_ADDRESS', process.env.MAC_ADDRESS);
console.log('CONTAINER_IP', process.env.CONTAINER_IP);

// custom logger for bugsnag
export const customBugsnagLogger = {
	addMetadata(key: string, value: any) {
		Bugsnag?.addMetadata(key, value);
	},
	setUser(user: any) {
		const { id, uuid, lastName, firstName, centerCode } = user;
		Bugsnag?.setUser(
			id,
			`${uuid}@${centerCode}.com`,
			`${firstName} ${lastName}`
		);
	},
	debug(debug: string) {
		Bugsnag?.leaveBreadcrumb(debug);
	},
	info(error: any) {
		Bugsnag?.notify(error, (event) => {
			event.severity = 'info';
		});
	},

	warn(error: any) {
		Bugsnag?.notify(error, (event) => {
			event.severity = 'warning';
		});
	},

	error(error: any) {
		if (error) {
			Bugsnag?.notify(error, (event: any) => {
				event.severity = 'error';
			});
		}
	},
};
