import React, { useState, useEffect } from 'react';
import { useVerifyTempTokenMutation } from '@/apollo/gql/generated/graphql';
import { useRouter } from 'next/router';
import CookieService from '@/services/cookies';

type Props = {
	children: React.ReactChild | React.ReactChild[];
};

const RouteGuard = ({ children }: Props) => {
	const router = useRouter();
	const [verifyTempToken] = useVerifyTempTokenMutation();
	const { query } = router;
	const [authorized, setAuthorized] = useState(false);

	useEffect(() => {
		const authCheck = async (url: string) => {
			// redirect to login page if accessing a private page and not logged in
			const publicPaths = ['/dashboard'];
			const publicEndString = '/';
			const path = url.split('?')[0];
			const tempId = query.temp_id as string;

			if (
				!localStorage.getItem('token') &&
				!publicPaths.includes(path) &&
				!path.endsWith(publicEndString) &&
				!tempId
			) {
				setAuthorized(false);
				router.push({
					pathname: '/',
					query: router.query,
				});
			} else if (tempId) {
				const tempTokenData = await verifyTempToken({
					variables: { temp_id: tempId },
				});
				console.log(tempTokenData.data?.verifyTempToken);
				if (tempTokenData.data?.verifyTempToken) {
					const options = { path: '/' };
					CookieService.set(
						'access_token',
						tempTokenData.data?.verifyTempToken.token as string,
						options
					);
					localStorage.setItem(
						'token',
						tempTokenData.data?.verifyTempToken.token || ''
					);
					localStorage.setItem(
						'user',
						JSON.stringify(tempTokenData.data?.verifyTempToken)
					);
					const currentUrl = window.location.href;
					const currentUrlWithoutQuery = currentUrl.split('?')[0];
					router.push(currentUrlWithoutQuery);
				} else {
					setAuthorized(false);
					router.push({
						pathname: '/',
						query: router.query,
					});
				}
			} else {
				setAuthorized(true);
			}
		};

		authCheck(router.asPath);

		// on route change start - hide page content by setting authorized to false
		const hideContent = () => setAuthorized(false);
		router.events.on('routeChangeStart', hideContent);

		// on route change complete - run auth check
		router.events.on('routeChangeComplete', authCheck);

		// unsubscribe from events in useEffect return function
		return () => {
			router.events.off('routeChangeStart', hideContent);
			router.events.off('routeChangeComplete', authCheck);
		};
	}, [router, router.asPath, router.events]);

	return authorized ? children : null;
};

export default RouteGuard;
