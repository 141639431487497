import '@/services/wdyr';
import '../styles/globals.css';
import '../lib/bugsnag';
import 'react-toastify/dist/ReactToastify.min.css';
import { ApolloProvider } from '@apollo/client';
import I18nProvider from 'next-translate/I18nProvider';
import type { AppProps } from 'next/app';
import { Grommet } from 'grommet';
import customTheme from '@/styles/theme';
import colorBlindThemeGreen from '@/styles/colorBlindThemeGreen';
import colorBlindThemeBlue from '@/styles/colorBlindThemeBlue';
import RouteGuard from '@/config/routeGuard';
import { client } from '@/apollo/client';
import { ToastContainer } from 'react-toastify';
import cookies from '@/services/cookies';
import Script from 'next/script';
import Bugsnag from '@bugsnag/js';

import React from 'react';
import { RecoilRoot } from 'recoil';
import ES from '../../locales/es/default.json';
import EN from '../../locales/en/default.json';

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

type ThemeNames =
	| 'customTheme'
	| 'colorBlindThemeGreen'
	| 'colorBlindThemeBlue';

const MyApp = ({ Component, pageProps }: AppProps) => {
	const lang = cookies.get('NEXT_LOCALE');
	const currentTheme: ThemeNames =
		cookies.get('CURRENT_THEME') || 'customTheme';
	console.log('welcome to mathnasium');	

	const languages = { en: EN, es: ES };

	const themeMapping: Record<
		ThemeNames,
		| typeof customTheme
		| typeof colorBlindThemeGreen
		| typeof colorBlindThemeBlue
	> = {
		customTheme,
		colorBlindThemeGreen,
		colorBlindThemeBlue,
	};
	const themeObject = themeMapping[currentTheme];

	return (
		<ErrorBoundary>
			<ApolloProvider client={client}>
				<I18nProvider
					lang={lang}
					namespaces={{ default: languages[lang || 'en'], ...languages }}
				>
					<RecoilRoot>
						<Grommet theme={themeObject}>
							<RouteGuard>
								<Script src="/static/Stage-0.1.4.js" />
								<Script src="/static/fscreen-1.0.1.js" />
								<Script src="/static/MyMath.js" />
								<Script src="https://cdn.jsdelivr.net/npm/pdfjs-dist@2.2.228/build/pdf.min.js" />
								<Script src="/static/tex-svg.js" />
								<Component {...pageProps} />
								<ToastContainer />
							</RouteGuard>
						</Grommet>
					</RecoilRoot>
				</I18nProvider>
			</ApolloProvider>
		</ErrorBoundary>
	);
};

export default MyApp;
