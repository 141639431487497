import whyDidYouRender from '@welldone-software/why-did-you-render';
import React from 'react';

console.log(process.env.NODE_ENV, 'process.env.NODE_ENV')

if (process.env.NODE_ENV === 'development') {
	console.log('whyDidYouRender enabled');
	whyDidYouRender(React, {
		trackAllPureComponents: true,
		logOnDifferentValues: true,
		trackHooks: true,
		diffNameColor: 'red',
		notifier: (updateInfo) => {
			console.log(
				`%cComponent: ${updateInfo.displayName} %cHook: ${updateInfo.hookName}`,
				'color: blue'
			);

			// if (updateInfo.reason.propsDifferences) {
			// 	console.log('Changed props:', updateInfo.reason.propsDifferences);
			// }
			// if (updateInfo.reason.stateDifferences) {
			// 	console.log('Changed state:', updateInfo.reason.stateDifferences);
			// }
			// if (updateInfo.reason.hookDifferences) {
			// 	console.log('Changed hooks:', updateInfo.reason.hookDifferences);
			// }

			if (updateInfo.reason.hookDifferences) {
				updateInfo.reason.hookDifferences.forEach((hookDifference) => {
					console.log(
						`${hookDifference?.pathString}:`,
						'Old:',
						hookDifference.prevValue,
						'New:',
						hookDifference.nextValue
					);
				});
			}
		},
	});
}
