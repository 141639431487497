import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
	apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '',
	appVersion: `1.4.3-${process.env.AWS_DEFAULT_REGION}`,
	plugins: [new BugsnagPluginReact()],
	appType: 'mathnasium-frontend',
	enabledReleaseStages: ['production', 'staging', 'development'],
	releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE || 'development',
	enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'user'],
});
