import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';

const brandColor = '#00A68E';
const hoverBrandColor = '#008972';
const warningColor = '#e4b303';
const darkBackground = '#1A1F2B';
const lightBackground = '#EFEFEF';

const colorBlindThemeGreen = deepMerge(grommet, {
	defaultMode: 'light',
	global: {
		colors: {
			brand: brandColor,
			secondary: brandColor,
			warning: warningColor,
			'background-back': {
				dark: darkBackground,
				light: lightBackground,
			},
			'background-front': {
				dark: '#222938',
				light: '#FFFFFF',
			},
			'background-contrast': {
				dark: '#FFFFFF08',
				light: '#11111108',
			},
			'background-custom': {
				dark: '#0E5265',
				light: '#00C8FF',
			},
		},
	},
	button: {
		extend: (props) => {
			let styles = '';
			if (props.primary) {
				styles += `
					&:hover {
						background-color: ${hoverBrandColor};
					}
				`;
			} else if (props.secondary) {
				styles += `
				`;
			} else {
				// Default button styles
				styles += `
				`;
			}
			return styles;
		},
	},
	tab: {
		active: {
			color: 'brand',
		},
		border: {
			color: {
				dark: 'black',
				light: 'black',
			},
			active: {
				color: {
					dark: 'brand',
					light: 'brand',
				},
			},
			hover: {
				color: {
					dark: 'brand',
					light: 'brand',
				},
			},
		},
		color: 'black',
		hover: {
			color: {
				dark: 'brand',
				light: 'brand',
			},
		},
	},
});

export default colorBlindThemeGreen;
